<template>
  <div class="arhive-request">
    <transition name="fade" mode="out-in">
      <base-loader v-if="isLoading" class="arhive-request__loader" />
      <card-block v-else :title="$t('title')" class="arhive-request__block">
        <transition name="fade" mode="out-in">
          <base-table
            v-if="isReady"
            :columns="tableHead"
            :list="paginList"
            class="arhive-request__table"
          >
            <!--            @sort-change="onSortChange"-->
            <base-table-row
              v-for="(item, i) in paginList"
              :key="i"
              :columns="tableHead"
              :item="item"
              col-class="arhive-request__table-item__col"
            >
              <template #id>
                <router-link
                  :to="{ name: 'ArchivTicket', params: { id: item.id } }"
                  class="arhive-request__table-item__name standart-text"
                >
                  {{ item.id }}
                </router-link>
              </template>
              <template #theme>
                <span class="arhive-request__item-realname standart-text">
                  {{ item.name }}
                </span>
              </template>
              <template #lastUpdate>
                <span class="arhive-request__item-email standart-text">
                  {{ item.last_message }}
                </span>
              </template>
              <template #status>
                <main-label :color="'success'" theme="plain"> {{ $t('arhiv') }} </main-label>
              </template>
            </base-table-row>
          </base-table>
        </transition>
        <NewPagination
          :step="pagin.step"
          :start="pagin.start || 0"
          :count="allTicketsArhive.length"
          type="base"
          @set-step="setStep"
          @new-pagination="changePagination"
        />
      </card-block>
    </transition>
  </div>
</template>

<script>
/**
 * @TODO:
 * - использовать для страницы тот же компонент, что и для открытых тикетов
 * т.к. компоненты (и структура получаемых данных) не отличаются.
 * данные запрашивать и получать чеез соответствующий модуль в сторе
 * который передавать (через мета-данные маршрута или пропсы router-view)
 */
import CardBlock from '@/layouts/Profile/Main/components/CardBlock';
import BaseTable from '@/components/Table/BaseTable';
import BaseTableRow from '@/components/Table/BaseTableRow';
import notifications from '@/mixins/notifications';
import mixin from '@/layouts/Profile/mixins';
import showErrorModal from '@/mixins/showErrorModal';
import MainLabel from '@/components/Labels/MainLabel.vue';
import NewPagination from '@/components/Pagination/NewPagination';

export default {
  name: 'OpenTicket',
  components: {
    CardBlock,
    BaseTable,
    BaseTableRow,
    MainLabel,
    NewPagination,
  },
  mixins: [notifications, mixin, showErrorModal],
  data() {
    return {
      isLoading: true,
      pagin: {
        start: 0,
        step: 10,
      },
      stepOptions: [10, 25, 50, 100, 'all'],
      tableHead: [
        {
          key: 'id',
          label: 'ID',
          style: {
            width: '80px',
            maxWidth: '100px',
          },
          // sort: {
          //   prop: 'id',
          //   order: 'asc',
          // },
        },
        {
          key: 'theme',
          label: this.$t('tableHead.theme'),
          style: {
            whiteSpace: 'nowrap',
            width: '300px',
            maxWidth: '360px',
          },
          // sort: {
          //   prop: 'theme',
          //   order: 'asc',
          // },
        },
        {
          key: 'lastUpdate',
          label: this.$t('tableHead.lastUpdate'),
          style: {
            width: '250px',
            maxWidth: '260px',
          },
          // sort: {
          //   prop: 'lastUpdate',
          //   order: 'asc',
          // },
        },
        {
          key: 'status',
          label: this.$t('tableHead.status'),
          style: {
            width: '104px',
          },
          // sort: {
          //   prop: 'status',
          //   order: 'asc',
          // },
        },
      ],
      settingsModal: null,
      rightsModal: null,
    };
  },
  computed: {
    allTicketsArhive() {
      return this.$store.getters['moduleSupport/GET_ALL_TICKETS_ARHIVE'];
    },
    paginList() {
      if (this.pagin.step === 'all') return this.allTicketsArhive;
      const { start, step } = this.pagin;
      const end = start + step;
      return this.allTicketsArhive.slice(start, end);
    },
    isReady() {
      return this.allTicketsArhive && this.allTicketsArhive.length;
    },
  },
  // watch: {
  //   sort: {
  //     handler(val, old) {
  //       this.updateSort(val);
  //     },
  //     deep: true,
  //   },
  // },
  mounted() {
    if (this.isReady) this.isLoading = false;
    else if (!this.loading && !this.isReady) this.fetchAllTicketsArhive();
  },

  methods: {
    onStepChange(value) {
      this.pagin.step = value;
    },
    onPageChange(value) {
      this.pagin.start = this.pagin.step * (value - 1);
    },
    fetchAllTicketsArhive() {
      this.isLoading = true;
      return this.$store
        .dispatch('moduleSupport/fetchAllTicketsArhive')
        .finally(() => (this.isLoading = false));
    },
    setStep(value) {
      this.pagin.step = value;
    },
    changePagination(value) {
      this.pagin.step = value.rows;
      this.pagin.start = value.first;
    },
  },
};
</script>
<i18n>
{
  "ru": {
    "title": " ",
    "arhiv": "Вопрос решен",
    "tableHead": {
      "theme": "Тема обращения",
      "lastUpdate": "Последнее обращение",
      "status": "Статус"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.arhive-request {
  &__table {
    margin-bottom: 1.25rem;
  }

  &__after {
    +breakpoint(sm-and-up) {
      flexy(flex-start, flex-start);
    }

    &-text {
      margin-bottom: 1.5rem;

      +breakpoint(sm-and-up) {
        margin-right: 1.5rem;
        margin-bottom: 0;
      }
    }
  }

  &__empty {
    max-width: 50rem;
  }

  &__add {
    width: 100%;
    +breakpoint(sm-and-up) {
      width: auto;
      flex: 0 0 auto;
    }
  }

  >>> &__item {
    vertical-align: middle;

    +breakpoint(md-and-down) {
      min-width: min-content;
      max-width: unset !important;
    }

    +breakpoint(ml-and-up) {
      text-overflow: ellipsis;
      overflow: hidden;
      position: relative;
      z-index: 1;
    }

    &:hover {
      background: var(--main-card-bg);
      text-overflow: unset;
      overflow: hidden;
      z-index: 2;
    }
  }

  &__item {
    &-name,
    &-realname,
    &-email {
      white-space: nowrap;
      text-overflow: ellipsis;
      position: relative;
      padding: 0.75rem;
      margin: -0.75rem;
      box-sizing: content-box;
      max-width: 100%;

      &::after {
        +breakpoint(ml-and-up) {
          content: '';
          display: block;
          size(2.5em, 100%);
          absolute(0px, 100%);
          position absolute;
          top: 0;
          left: calc(100% - 0.75rem);
          background: linear-gradient(to right, var(--main-card-bg) 0%, transparent 100%);
          opacity: 0;
        }
      }

      &:hover::after {
        +breakpoint(ml-and-up) {
          opacity: 1;
        }
      }
    }
  }
}
.arhive-request__table-item {
  font-size: 1rem;
  >>> &__col {
    vertical-align: middle;

    +breakpoint(xs-and-up) {
      text-overflow: ellipsis;
      overflow: hidden;
      position: relative;
      z-index: 1;
    }

    &:hover {
      background: var(--main-card-bg);
      text-overflow: unset;
      z-index: 2;
    }
  }
 &__link {
    position: relative;
    padding: 0.5em;
    margin: -0.5em;
    color: $primary-color;
    text-decoration: none;
    white-space: nowrap;
    background: inherit;
    &:hover {
      color: darken($primary-color, 20%);
    }

    &:hover::after {
      +breakpoint(ml-and-up) {
        content: '';
        display: block;
        size(2.5em, 100%);
        absolute(0px, 100%);
        background: linear-gradient(to right, var(--main-card-bg) 0%, transparent 100%);
      }
    }
  }
}
</style>
